import React, { useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import CommentSection from '../CommentModule'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"

const useStyles = makeStyles((theme) => ({
  bannerCtr: {
    backgroundColor: "#15684b",
    paddingTop: "5rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    paddingBottom: "8rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingBottom: "8rem",
    },
  },
  h1: {
    fontSize: "2.5rem",
    paddingBottom: "1.75rem",
    fontWeight: 700,
    color: "#fff",
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  authorName: {
    color: "#fff",
    fontSize: ".9rem",
  },
  postImage: {
    marginTop: "-8.5rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
  },
  postImageImg: {
    borderRadius: 10,
  },
  postCtr: {
    paddingTop: "2rem",
    maxWidth: 730,
    paddingBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1.25rem",
      paddingRight: "1.25rem",
    },
  },
  postBody: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "1.125rem",
    lineHeight: "1.8",
    "& p": {
      fontFamily: '"Open Sans", sans-serif',
      marginBottom: "1.3rem",
    },
    "& strong": {
      fontWeight: "bold",
    },
    "& h2, h3, h4, h5": {
      marginTop: "2.25rem",
      marginBottom: "1.75rem",
      fontSize: "2rem",
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 800,
    },
    "& ul": {
      "& > li:last-child": {
        marginBottom: "1.3rem",
      },
    },
    "& img": {
      maxWidth: "-webkit-fill-available",
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& blockquote": {
      fontSize: "1.4rem",
      margin: "2.5rem",
      fontFamily: "'Source Sans Pro', sans-serif",
      fontStyle: "italic",
      color: "#555555",
      padding: "1.2rem 1.875rem 1.2rem 4.68rem",
      borderLeft: `8px solid #15684b`,
      lineHeight: 1.6,
      position: "relative",
      background: "#EDEDED",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.05rem",
        margin: "1rem 0",
      },
      "& p": {
        marginBottom: 0,
      },
      "&:before": {
        fontFamily: "Arial",
        content: "'\\201C'",
        color: `#15684b`,
        fontSize: "4em",
        position: "absolute",
        left: 10,
        top: -10,
      },
      "&:after": {
        content: "''",
      },
    },
    "& hr": {
      margin: "1.75rem 0",
    },
    "& table": {
      width: "100%",
      position: "relative",
      borderCollapse: "collapse",
      tableLayout: "auto",
      marginBottom: "1rem",
      "& thead": {
        backgroundColor: "#15684b",
        color: "#fff",
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 600,
        "& th": {
          padding: "0.1rem 0.3rem",
        },
      },
      "& tbody": {
        fontSize: "1rem",
        "& tr:nth-of-type(odd)": {
          backgroundColor: "rgba(0,0,0,0.05)",
        },
        "& td": {
          borderTop: "1px solid #e6e6e6",
          padding: "0.3rem",
        },
      },
    },
  },
  shareCtr: {
    position: "fixed",
    top: 140,
    padding: '.5rem',
    border: '1px solid #ebebeb',
    [theme.breakpoints.only("xl")]: {
      marginLeft: "-25rem",
    },
    [theme.breakpoints.only('lg')]:{
      marginLeft: '-15rem',
    },
    [theme.breakpoints.only('md')]: {
      left: '2.5%',
    },
  },
}))

export default function SeoPostTemplate({ postTitle, postBody, postImage, authorImg, authorName, updatedAt, createdAt }) {
  const classes = useStyles()
  const [fbShares, setFbShares] = React.useState(null)

  useEffect(() => {

    const abortCont = new AbortController()
  
      const url = window.location.href
      const encodedUri = encodeURIComponent(url) 

      fetch(
        `https://graph.facebook.com/v12.0/?id=${encodedUri}&fields=engagement&access_token=${process.env.FB_ACCESS_TOKEN}`,
        {
          signal: abortCont.signal,
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error("Response failed")
          }
          return res.json()
        })
        .then((resp) => {
          if (typeof resp.engagement.share_count === "number") {
            let sharesNum = resp.engagement.share_count
            setFbShares(sharesNum.toLocaleString(undefined))
          }
        })
        .catch((err) => {
          // console.log(err)
          setFbShares(null)
        })

      return () => abortCont.abort()
  }, [])

  const handleShare = (e) => {
        e.preventDefault()

        switch (e.currentTarget.id) {
          case "facebook":
            let followurlf = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`

            window.open(`${followurlf}`, `_blank`)
            break
          case "twitter":
            let followurlt = `https://twitter.com/intent/tweet?text=${`${postTitle}`}&url=${
              window.location.href
            }`
            window.open(`${followurlt}`, `_blank`)
            break
          default:
            break
        }
  }

  return (
    <>
      <Container maxWidth="lg" className={classes.bannerCtr}>
        <Container maxWidth="sm">
          <Grid container justify="center">
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.h1} align="center">
                {postTitle}
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              item
              xs={12}
              style={{ paddingBottom: "3rem" }}
            >
              <Avatar
                src={`https://${authorImg.images.sources[0].srcSet
                  .split("?")[0]
                  .substring(2)}`}
                alt="Joe Snappy"
                style={{ marginRight: ".9rem" }}
                variant="circle"
              />
              <Typography variant="body1" className={classes.authorName}>
                by {authorName} {" · "}{" "}
                {updatedAt ? `Updated ${updatedAt}` : createdAt}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Grid container justify="center" alignItems="center" item xs={12}>
        <GatsbyImage
          alt={postTitle}
          image={postImage}
          className={classes.postImage}
          imgClassName={classes.postImageImg}
        />
      </Grid>

      <Container className={classes.postCtr}>
        <Hidden smDown>
          <Paper className={classes.shareCtr} elevation={0}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography
                  variant="body1"
                  align="center"
                  style={{
                    fontSize: ".85rem",
                    fontWeight: 600,
                    fontFamily: "Open Sans",
                  }}
                >
                  {fbShares ? fbShares : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  align="center"
                  style={{
                    fontSize: ".85rem",
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    paddingBottom: ".50rem",
                  }}
                >
                  {fbShares ? "shares" : 'Share'}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleShare} id="facebook">
                  <FacebookIcon style={{ color: "#4267B2", fontSize: 26 }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={handleShare} id="twitter">
                  <TwitterIcon style={{ color: "#1DA1F2", fontSize: 26 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Hidden>
        <Grid container justify="center">
          <Grid item xs={12}>
            <div
              className={classes.postBody}
              dangerouslySetInnerHTML={{
                __html: postBody,
              }}
            ></div>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        justify="center"
        style={{ borderTop: "1px solid #ebebeb", paddingTop: "3rem", paddingBottom: '3rem', backgroundColor: 'rgba(250,250,250,1)' }}
      >
        <CommentSection />
      </Grid>
    </>
  )
}
