import React from "react"
import { Link } from "gatsby"
import AppBar from "@material-ui/core/AppBar"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"
import Container from '@material-ui/core/Container'
import Toolbar from "@material-ui/core/Toolbar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import CssBaseline from "@material-ui/core/CssBaseline"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import logo from "../../assets/images/logo.svg"

const routes = [
  { name: "Home", url: "/" },
  { name: "About Us", url: "/about/" },
  { name: "Contact", url: "/contact/" },
]


const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "0rem",
    },
  },

  darkDrawerIconCtr: {
    marginLeft: "auto",
  },
  lightTabContainer: {
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      marginRight: "0rem",
    },
    "&:hover": {
      "& a": {
        color: "rgba(0,0,0,.7)",
        textDecoration: "none",
      },
    },
  },
  lightTab: {
    fontFamily: "Open Sans",
    fontSize: ".875rem",
    fontWeight: 700,
    letterSpacing: 1,
    minWidth: 10,
    marginLeft: ".5rem",
    opacity: 1,
    color: fade(theme.palette.text.primary, 0.7),
    textTransform: "capitalize",
    "&:hover": {
      color: "rgba(0,0,0,.9)",
      textDecoration: "none",
    },
  },
  lightNav: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: "#fff",
    borderBottom: "1px solid #e6e6e6",
  },
  lightDrawer: {
    marginTop: "4rem",
    backgroundColor: theme.palette.background.paper,
    color: "#14151a",

    "&:hover": {
      "& a": {
        textDecoration: "none",
        color: "#14151a",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3.5rem",
    },
  },
  drawerItem: {
    fontFamily: "'Open Sans', sans-serif",
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: 600,
    opacity: 0.9,
    padding: ".25em 3em",
  },
  logoIconCtr: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  hoverFix: {
    "&:hover": {
      "& a": {
        color: "#14151a",
      },
    },
  },
  bannerText: {
    color: "#14151a",
    marginBottom: 0,
    "&:hover": {
      textDecoration: "none",
      color: "#14e3a1",
    },
  },
  drawerToolbar: {
    marginTop: "3rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem",
    },
  },
}))

export default function Navbar(props) {
  const classes = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  })


  const drawer = (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.lightDrawer,
        }}
      >
        {!trigger && <div className={classes.drawerToolbar} />}
        <List>
          {routes.map((route, i) => {
            return (
              <ListItem
                divider
                key={`${route.name}${i}`}
                component={Link}
                to={route.url}
                button
                onClick={() => {
                  setOpenDrawer(false)
                }}
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  {route.name}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      </SwipeableDrawer>
      <IconButton
        aria-label="navigation menu"
        className={classes.darkDrawerIconCtr}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon color="inherit" />
      </IconButton>
    </>
  )

  const tabs = (
    <>
          <Tabs
            TabIndicatorProps={{ style: { background: "#26a27b" } }}
            value={false}
            className={classes.lightTabContainer}
          >
            {routes.map((route, i) => {
              return (
                <Tab
                  component={Link}
                  to={route.url}
                  className={classes.lightTab}
                  key={`${route.name}${i}`}
                  label={route.name}
                  aria-label={route.name}
                  disableRipple
                />
              )
            })}
          </Tabs>
    </>
  )

  return (
    <>
      <CssBaseline />
      <AppBar position="static" className={classes.lightNav} elevation={0}>
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              aria-label="Back to Home Page"
              component={Link}
              to="/"
              disableRipple
              className={classes.logoIconCtr}
            >
              <img
                src={logo}
                className={classes.logo}
                style={{ textTransform: "none" }}
                alt="Snappy Web Design Logo"
                height="30px"
              />
            </IconButton>
            {matchesSM ? drawer : tabs}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}
