import React, { useEffect } from "react"

import Grid from "@material-ui/core/Grid"

export default function CommentModule() {
  useEffect(() => {
    window["gc_params"] = {
      graphcomment_id: "Snappy-Web-Design", // replace the string with your GraphComment ID

      fixed_header_height: 0,
    }
    ;(function () {
      var gc = document.createElement("script")
      gc.type = "text/javascript"
      gc.async = true
      gc.src = "https://graphcomment.com/js/integration.js?" + Date.now()
      ;(
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(gc)
    })()
  }, [])
  return (
    <Grid item xs={12}>
      <div id="graphcomment"></div>
    </Grid>
  )
}
