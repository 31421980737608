
import React from "react"
import PropTypes from "prop-types"
import Navbar from '../SeoPosts/seoNavbar'
import Footer from './seoFooter'
import '../../assets/stylesheets/layout.scss'
import { ThemeProvider } from "styled-components"
import theme from "../../assets/stylesheets/theme"


const Layout = ({ children, }) => {

  return (
    <React.Fragment>
    <ThemeProvider theme={theme}>
      <Navbar />
      <main>
        {children}
      </main>
      <Footer />
    </ThemeProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
