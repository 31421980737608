import React from 'react'
import {Link} from '@reach/router'

import {makeStyles, useTheme} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FaFacebookSquare, FaTwitter, FaLinkedin } from "react-icons/fa"

import logo from '../../assets/images/logo.svg'

const useStyles = makeStyles((theme) => ({
  footerCtr: {
    // paddingTop: "1.5rem",
    paddingTop: "3.85rem",
    paddingBottom: "4rem",
    backgroundColor: "#f8f9fa",
    color: "#202124",
    // marginTop: "2.35rem",
  },
  socialIco: {
    fill: "#202124",
    marginRight: "1rem",
    fontSize: "1.25rem",
  },
  disableRipple: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  href: {
    marginRight: "2rem",
    color: "#5f6368",
    fontSize: ".9rem",
    "&:hover": {
      color: "#202124",
      textDecoration: "none",
    },
  },
}))

export default function SeoFooter() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <React.Fragment>
      <Grid container className={classes.footerCtr}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="body1" style={{ marginRight: "1.5rem", fontSize: '.95rem'}}>
                    Follow Us
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="facebook link"
                    component="a"
                    href="https://www.facebook.com/Snappy-Web-Design-104414755169482"
                    className={classes.disableRipple}
                  >
                    <FaFacebookSquare className={classes.socialIco} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="twitter link"
                    component="a"
                    href="https://twitter.com/snappy_web"
                    className={classes.disableRipple}
                  >
                    <FaTwitter className={classes.socialIco} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="linkedin link"
                    component="a"
                    href="https://www.linkedin.com/in/snappywebdesign/"
                    className={classes.disableRipple}
                  >
                    <FaLinkedin className={classes.socialIco} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ width: "100%", margin: "1.5rem 0" }} />
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton
                    aria-label="snappy web design logo"
                    component={Link}
                    to="/"
                    disableRipple
                    className={classes.disableRipple}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginRight: matchesXS ? '1.75rem' : "2.5rem",
                    }}
                  >
                    <img src={logo} alt='snappy web design logo' style={{ height: matchesXS ? 20 : 28 }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className={classes.href}
                    component={Link}
                    to="/blog/"
                  >
                    Blog
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className={classes.href}
                    component={Link}
                    to="/about/"
                  >
                    About
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className={classes.href}
                    component={Link}
                    to="/contact/"
                  >
                    Contact
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </React.Fragment>
  )
}

