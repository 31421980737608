import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

// import Layout from "../components/layout"
import Layout from "../components/SeoPosts/seoLayout"
import Seo from "../components/seo"
import SeoPostTemplate from "../components/SeoPosts/SeoPostTemplate"

export default function SeoPages({ data }) {
  const {
    postTitle,
    metaDescription,
    updatedAt,
    createdAt,
    previewImage
  } = data.contentfulSeoPosts
  const authorName = data.contentfulSeoPosts.author.name
  const authorImg = getImage(data.contentfulSeoPosts.author.profilePicture)
  const postImage = getImage(previewImage)
  const postBody = data.contentfulSeoPosts.postBody.childMarkdownRemark.html
  // const {
    // postTitle,
    // metaDescription,
    // author,
    // featured,
    // createdAt,
    // updatedAt,
    // blogCreatedAt,
    // blogUpdatedAt,
    // postBody: { postBody },
    // previewImage,
  // } = data.contentfulSeoPosts
  // const { author } = data.site.siteMetadata
  // const body = data.contentfulSeoPost.body.childMarkdownRemark.html
  // const pathToImage = getImage(previewImage)


  return (
    <Layout>
      <Seo
        title={postTitle}
        article={true}
        image={`https://${previewImage.gatsbyImageData.images.sources[0].srcSet
          .split("?")[0]
          .substring(2)}`}
        description={metaDescription}
        blogCreatedAt={createdAt}
        blogUpdatedAt={updatedAt}
      />
      <SeoPostTemplate 
      postTitle={postTitle}
      postBody={postBody}
      postImage={postImage}
      authorImg={authorImg}
      authorName={authorName}
      updatedAt={updatedAt}
      createdAt={createdAt}
      />
    </Layout>
  )
}

export const query = graphql`
  query getSingleSeoPost($title: String) {
    contentfulSeoPosts(postTitle: { eq: $title }) {
      postTitle
      metaDescription
      updatedAt(formatString: "MMM. DD, YYYY")
      createdAt(formatString: "MMM. DD, YYYY")
      author {
        id
        name
        profilePicture {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 80
            height: 100
          )
        }
      }
      postBody {
        childMarkdownRemark {
          html
        }
      }
      previewImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          height: 313
          quality: 95
        )
      }
    }
  }
`
